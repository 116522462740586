<template>
  <div class="shareDetailBar">
    <div class="titleView">
      <div class="barBackGroundView"></div>
      <div class="barTitle">
        科室共享概览调度
      </div>
    </div>


    <div class="barContent" id="shareDetailBar">

    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'

export default {
  name: 'shareDetailBar',
  props: {
    chartList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  watch: {
    'chartList': {
      handler(value) {
        this.$nextTick(() => {
          this.setOptions()
        })
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      myChart: null
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    changeHeight: function(res) {
      this.height = `calc(100% - ${res.height}px)`
      this.$nextTick(() => {
        this.setOptions()
      })
    },
    setOptions: function() {
      let xAxis = []
      let borrow = []
      let loan = []
      for (let i = 0; i < this.chartList.length; i++) {
        let data = this.chartList[i]
        xAxis.push(data.tenantName)
        borrow.push(data.borrow)
        loan.push(data.loan)
      }

      let dataZoom = []

      if (xAxis.length > 10) {
        dataZoom = [
          {
            show: true,
            start: 0,
            end: xAxis.length / 5.0
          },
          {
            type: 'inside'
          },
          {
            type: 'slider'
          }
        ]
      }

      if (this.myChart == null) {
        this.myChart = echarts.init(document.getElementById('shareDetailBar'))
      }

      let option = {
        color: ['#799FF9', '#f3ba8c'],
        dataZoom: dataZoom,
        tooltip: {
          trigger: 'axis',
          formatter: function(params) {
            let str = ''
            params.forEach((item, idx) => {
              let msg = `${item.name} <span style="color:${item.color}">${item.seriesName}</span> ${typeof item.value == 'undefined' || item.value == null ? 0 : item.value}个`
              str += msg + '<br>'
            })
            return str
          }
        },
        legend: {
          icon: 'rect',
          itemWidth: 8, // 设置宽度
          itemHeight: 8, // 设置高度
          left: 20,
          top: 0
        },
        xAxis: [
          {
            type: 'category',
            data: xAxis,
            axisLabel: { interval: 0, rotate: 40 }
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        grid: [{
          right: '5%', left: '5%', bottom: '5%', top: '20%', containLabel: true
        }],
        series: [
          {
            name: '借用',
            type: 'bar',
            tooltip: {
              valueFormatter: function(value) {
                return value
              }
            },
            data: borrow
          },
          {
            name: '归还',
            type: 'bar',
            tooltip: {
              valueFormatter: function(value) {
                return value
              }
            },
            data: loan
          }
        ]
      }
      this.myChart.setOption(option)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@styles/variables.scss";

.shareDetailBar {
  width: 100%;
  height: calc(100% - 275px);
  background-color: #f0f6f8;
  padding-top: 10px;

  .titleView {
    height: 25px;
    width: calc(100% - 20px);
    transform: translate(10px, 0px);

    .barBackGroundView {
      width: 130px;
      height: 15px;
      background-color: #D2DAFF;
      position: relative;
      top: 20%;
    }

    .barTitle {
      width: 130px;
      position: relative;
      top: -80%;
      height: 25px;
      line-height: 25px;
      font-size: 15px;
      font-weight: bold;
      text-align: center;
    }
  }


  .barContent {
    margin-top: 5px;
    background-color: white;
    width: calc(100% - 20px);
    height: calc(100% - 45px);
    transform: translate(10px, 0px);
  }

}
</style>
