<template>
  <div class="shareDetailGraph" id="shareDetailGraph" :style="{ 'background-image': 'url(' + bgImg + ')' }">
    <div class="titleView">今日共享调度概览</div>
    <div class="chartContent">

      <div class="circle" v-for="(item, index) in cssInfo.circleList"
           :style="{width: $changePxToRem(item.width + 'px'),
            height:$changePxToRem(item.height + 'px'),
            opacity:index == cssInfo.circleList.length - 1 ? 1 : item.alpha,
            'border-radius':$changePxToRem(item.height / 2.0 + 'px'), 'background-image': 'url(' + (index == cssInfo.circleList.length - 1 ? arcBgImg : '' )  + ')'}"
      ></div>

      <div
          class="circle"
          :style="{width: $changePxToRem(cssInfo.inCss.width + 'px'),
            height:$changePxToRem(cssInfo.inCss.height + 'px'),
            '--border-radius':$changePxToRem(cssInfo.inCss.height / 2.0  + 'px')}"
      >
        <div class="content" @click="pushDetail('', '在池', '')">
          <div class="title">{{ getValue('shareDevice') }}</div>
          <div class="status">在池</div>
        </div>

      </div>

      <div v-for="(item, index) in cssInfo.dataCssList" class="dataCircle" :style="{
           width:item.width,
           height:item.height,
            left:item.left,
           top:item.top,
           'border-radius':item.radius, }" @click="pushDetail(item.orderStatus, item.name, item.shareType)">
        <div class="title">
          {{ item.name }}
        </div>

        <div class="dataCircleView" :style="{'border-radius':item.radius, 'lineHeight':item.height}">
          {{ getValue(item.id) }}
        </div>
      </div>

    </div>

    <div class="chartTitle">
      今日收益
      <span>{{ inComeInfo.incomeCount }}</span>元
    </div>


  </div>

</template>

<script>
let bgImg = require('../../../../../../static/iconImage/deviceBgImg.png')
let arcBgImg = require('../../../../../../static/iconImage/inPool.png')

export default {
  name: 'shareDeailGraphV_3',
  props: {
    query: {
      type: Object,
      default: () => {
        return {}
      }
    },
    statusInfo: {
      type: Object,
      default: () => {
        return {}
      }
    },
    inComeInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      arcBgImg: arcBgImg,
      bgImg: bgImg,
      keys: [],
      cssInfo: {
        circleList: [],
        inCss: {
          width: 0,
          height: 0,
          alpha: 0
        },
        dataCssList: []
      }
    }
  },
  created() {

  },
  mounted() {
    this.$nextTick(() => {
      this.setup()
    })
  },
  methods: {
    pushDetail: function(orderStatus, name, shareType) {
      let query = { orderStatus: orderStatus, name: name, shareType: shareType, itemType: 'two' }
      for (const key in this.query) {
        query[key] = this.query[key]
      }
      this.$push('deviceShareStatus', query)
    },
    setup: function() {
      let viewHeight = 240
      let height = viewHeight * 1.1
      let width = window.screen.width

      let circelRadius = height >= width ? width : height

      let length = 4

      let list = []
      let minRadius = circelRadius / 3

      let space = (circelRadius - minRadius) / length

      let alpha = 0.12
      let minAlpha = 0.04

      let alphaSpace = (alpha - minAlpha) / length

      for (let i = 0; i < length; i++) {
        let r = circelRadius - i * space
        let a = minAlpha + i * alphaSpace
        list.push({
          width: r,
          height: r,
          alpha: a
        })
      }

      let in_r = circelRadius - length * space
      let in_a = 1

      this.cssInfo.inCss = {
        width: in_r,
        height: in_r,
        alpha: in_a
      }

      let dataCssList = []

      let dataList = [{ class: 'backCount', id: 'back', name: '已归还', orderStatus: 6, shareType: '' },
        { class: 'borrowCount', id: 'borrow', name: '借用中', orderStatus: '3,6', shareType: '' },
        { class: 'lockCount', id: 'subscribe', name: '锁定', orderStatus: 0, shareType: '' },
        { class: 'preCount', id: 'shareOrder', name: '预约', orderStatus: '', shareType: '' },
        { class: 'waitCount', id: 'approve', name: '待审批', orderStatus: 0, shareType: '' },
        { class: 'badCount', id: 'repairs', name: '报障', orderStatus: 4, shareType: '' }]

      let startAngle = -Math.PI / 4.0 // 初始角度
      let count = dataList.length
      let angleSpace = Math.PI / 4.0
      let radius = circelRadius * 0.38
      let subwidth = in_r * 0.45

      for (let i = 0; i < count; i++) {
        let a = startAngle + angleSpace * i
        let x = Math.cos(a) * radius
        let y = Math.sin(a) * radius
        let data = dataList[i]
        data['radius'] = `${this.$changePxToRem(`${subwidth / 2.0}px`)}`
        let left = `${this.$changePxToRem(`${(x + subwidth / 2.0)}px`)}`
        data['left'] = `calc(50% - ${left})`
        let top = `${this.$changePxToRem(`${(y + viewHeight / 2.0 + subwidth / 2.0)}px`)}`
        data['top'] = `calc(100% - ${top})`
        data['width'] = `${this.$changePxToRem(`${subwidth}px`)}`
        data['height'] = `${this.$changePxToRem(`${subwidth}px`)}`
        dataCssList.push(data)

        if (i == 2) {
          angleSpace = Math.PI / 2.0
        } else if (i == 3) {
          angleSpace = Math.PI / 4.0
        }

      }

      this.cssInfo.circleList = list
      this.cssInfo.dataCssList = dataCssList
    },
    getValue: function(key) {
      if (this.$valueIsExist(this.statusInfo, key)) {
        return this.statusInfo[key]
      }
      return '0'
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@styles/variables.scss";

.shareDetailGraph {
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100% 98%;

  .titleView {
    width: 100%;
    height: 20px;
    color: white;
    padding-left: 10px;
    padding-top: 10px;
    font-size: 15px;
  }

  .chartContent {
    width: 100%;
    height: 240px;
    position: relative;
    margin-top: 10px;

    .circle {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      overflow: hidden;
      background-repeat: no-repeat;
      background-size: 100%;

      .content {
        position: relative;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);


        .title {
          width: 100%;
          height: 25px;
          line-height: 25px;
          font-size: 30px;
          color: white;
          text-align: center;
        }

        .status {
          margin-top: 5px;
          width: 100%;
          height: 15px;
          line-height: 15px;
          font-size: 12px;
          text-align: center;
          color: white;
          font-weight: bold;
        }

      }

    }


    .dataCircle {
      position: absolute;
      background-color: white;
      //overflow: hidden;

      .title {
        position: absolute;
        height: 20px;
        top: -20px;
        width: 200%;
        text-align: center;
        color: white;
        left: 50%;
        transform: translate(-50%, 0px);
      }

      .dataCircleView {
        width: 100%;
        height: 100%;
        overflow: hidden;
        background: white;
        position: absolute;
        left: 0px;
        top: 0px;
        text-align: center;
        color: $color_primary;
      }



      .content {
        position: relative;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);


        .title {
          width: 100%;
          height: 20px;
          line-height: 20px;
          font-size: 21px;
          color: $color_primary;
          text-align: center;
        }

        .status {
          width: 100%;
          height: 15px;
          line-height: 15px;
          font-size: 12px;
          text-align: center;
          color: $color3;
          font-weight: bold;
        }

      }

    }

  }


  .chartTitle {
    width: calc(100% - 20px);
    height: 30px;
    line-height: 30px;
    transform: translate(10px, 0px);
    text-align: left;
    font-size: 16px;

    span {
      color: $color_primary;
    }

  }

}

</style>
