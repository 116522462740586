<template>
  <div class="shareDetailGraph" id="shareDetailGraph">
    <div class="chartContent" :style="{height:cssInfo.contentHeight, marginBottom:cssInfo.contentMagrinBottom}">
      <div class="backGroundView" :style="{
           width:cssInfo.backGround.width,
           height:cssInfo.backGroundView.height,
            left:cssInfo.backGround.left,
           top:cssInfo.backGround.top,
      }"
      >
        <div class="backGround"
             :style="{
           width:cssInfo.backGround.width,
           height:cssInfo.backGround.height,
           'border-radius':cssInfo.backGround.radius
      }"
        ></div>
      </div>

      <div class="inCount" @click="pushDetail('', '在池')">
        <svg-icon width="100px" height="100px"
                  name="inBackGround">
        </svg-icon>
        <div class="content">
          <div class="title">{{ statusInfo['shareDevice'] ? statusInfo['shareDevice'] : '-' }}</div>
          <div class="status">在池</div>
        </div>
      </div>

      <div v-for="(item, index) in keys" :class="item.class" :style="getStyleInfo(item.class)" @click="pushDetail(item.orderStatus, item.name)">
        <svg-icon :width="cssInfo.lockCount.width" :height="cssInfo.lockCount.width"
                  name="subInBackground"
        ></svg-icon>
        <div class="subContent">
          <div class="title">{{ statusInfo[item.id] ? statusInfo[item.id] : '-' }}</div>
          <div class="status">{{ item.name }}</div>
        </div>
      </div>


    </div>
    <div class="chartTitle">
      今日收益
      <span>{{ inComeInfo.incomeCount }}</span>元
    </div>

  </div>
</template>

<script>

export default {
  name: 'shareDetailGraph',
  props: {
    query: {
      type: Object,
      default: () => {
        return {}
      }
    },
    statusInfo: {
      type: Object,
      default: () => {
        return {}
      }
    },
    inComeInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      keys: [
        { class: 'backCount', id: 'back', name: '已归还', orderStatus:6 },
        { class: 'borrowCount', id: 'borrow', name: '借用中', orderStatus:3  },
        { class: 'lockCount', id: 'subscribe', name: '锁定', orderStatus:0  },
        { class: 'preCount', id: 'shareOrder', name: '预约', orderStatus:''  },
        { class: 'waitCount', id: 'approve', name: '待审批', orderStatus:0  },
        { class: 'badCount', id: 'repairs', name: '报障', orderStatus:4  }],
      cssInfo: {
        radius: 0,
        contentMagrinBottom: '20px',
        contentHeight: 'calc(100% - 30px)',
        backGroundView: {
          height: '0px'
        },
        backGround: {
          top: '0%',
          left: '0%',
          width: '0px',
          height: '0px',
          radius: '0px'
        },
        backCount: {
          top: '0%',
          left: '0%',
          width: '0px',
          height: '0px'
        },
        borrowCount: {
          top: '0%',
          left: '0%',
          width: '0px',
          height: '0px'
        },
        lockCount: {
          top: '0%',
          left: '0%',
          width: '0px',
          height: '0px'
        },
        preCount: {
          top: '0%',
          left: '0%',
          width: '0px',
          height: '0px'
        },
        waitCount: {
          top: '0%',
          left: '0%',
          width: '0px',
          height: '0px'
        },
        badCount: {
          top: '0%',
          left: '0%',
          width: '0px',
          height: '0px'
        }
      }
    }
  },
  created() {

  },
  mounted() {
    this.$nextTick(() => {
      this.setup()
    })
  },
  methods: {
    pushDetail: function(orderStatus, name) {
      let query = {orderStatus:orderStatus, name:name};
      for (const key in this.query) {
        query[key] = this.query[key];
      }
      this.$push('deviceShareStatus', query)
    },
    setup: function() {
      let radius = 140
      this.cssInfo.contentHeight = `${radius + 30}px`
      this.cssInfo.backGround.width = `${radius * 2}px`
      this.cssInfo.backGround.height = `${radius * 2}px`
      this.cssInfo.backGroundView.height = `${radius}px`

      this.cssInfo.backGround.radius = `${radius}px`
      this.cssInfo.backGround.top = `calc(100% - ${radius}px)`
      this.cssInfo.backGround.left = `calc(50% - ${radius}px)`

      let cut = 5
      let count = cut
      let angle = 180 / count
      let subwidth = 50
      this.cssInfo.contentMagrinBottom = subwidth / 2.0 + 20 + 'px'
      for (let i = 0; i < this.keys.length; i++) {
        let item = this.keys[i]
        let key = item.class
        let data = this.cssInfo[key]
        let a = i * angle / 180 * Math.PI
        let x = Math.cos(a) * radius
        let y = Math.sin(a) * radius
        data.left = `calc(50% - ${(x + subwidth / 2.0)}px)`
        data.top = `calc(100% - ${(y + subwidth / 2.0)}px)`
        data.width = `${subwidth}px`
        data.height = `${subwidth}px`
      }
      this.cssInfo.radius = radius
    },

    getStyleInfo: function(key) {
      return this.cssInfo[key]
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@styles/variables.scss";

.shareDetailGraph {
  width: calc(100% - 20px);
  transform: translate(10px, 0px);


  .chartContent {
    width: 90%;
    transform: translate(5.5555%, 0px);
    position: relative;


    .backGroundView {
      overflow: hidden;
      position: relative;


      .backGround {
        border: 1px dashed $color3;
      }
    }


    .inCount {
      position: absolute;
      top: calc(100% - 80px);
      left: calc(50% - 50px);
      width: 100px;
      height: 100px;

      .content {
        position: absolute;
        top: 50%;
        transform: translate(12.5%, -50%);
        width: 80%;

        .title {
          width: 100%;
          height: 25px;
          line-height: 25px;
          font-size: 25px;
          color: $color_primary;
          text-align: center;
        }

        .status {
          width: 100%;
          height: 15px;
          line-height: 15px;
          font-size: 15px;
          text-align: center;
          color: white;
        }

      }

    }


    .subContent {
      position: absolute;
      top: 50%;
      transform: translate(12.5%, -50%);
      width: 80%;

      .title {
        width: 100%;
        height: 15px;
        line-height: 15px;
        font-size: 15px;
        color: $color_primary;
        text-align: center;
      }

      .status {
        width: 100%;
        height: 12px;
        line-height: 12px;
        font-size: 12px;
        text-align: center;
        color: $color2;
      }

    }

    .backCount {
      position: absolute;
      background-color: white;
    }

    .borrowCount {
      position: absolute;
      background-color: white;
    }

    .lockCount {
      position: absolute;
      background-color: white;
    }

    .preCount {
      position: absolute;
      background-color: white;
    }

    .waitCount {
      position: absolute;
      background-color: white;
    }


    .badCount {
      position: absolute;
      background-color: white;
    }


  }

  .chartTitle {
    width: calc(100% - 20px);
    height: 30px;
    line-height: 30px;
    transform: translate(10px, 0px);

    span {
      color: $color_primary;
    }

  }

}

</style>
