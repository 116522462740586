<template>
  <van-pull-refresh v-model="loading" class="shareDetail" id="shareDetail" @refresh="getAllData">
    <div class="shareDetailView">
      <!--      <share-detail-graph :query="query" :in-come-info="incomeInfo" :status-info="statusInfo"></share-detail-graph>-->
      <share-deail-graph-v_3 :query="query" :in-come-info="incomeInfo" :status-info="statusInfo"
      ></share-deail-graph-v_3>
      <share-detail-bar :chart-list="chartList"></share-detail-bar>
    </div>
  </van-pull-refresh>

</template>

<script>
import ShareDetailGraph from '@/views/shareAndDispatch/device/deviceShareDetail/shareDetail/shareDetailGraph'
import ShareDetailBar from '@/views/shareAndDispatch/device/deviceShareDetail/shareDetail/shareDetailBar'
import ShareDeailGraphV_2 from '@/views/shareAndDispatch/device/deviceShareDetail/shareDetail/shareDeailGraphV_2'
import ShareDeailGraphV_3 from '@/views/shareAndDispatch/device/deviceShareDetail/shareDetail/shareDeailGraphV_3'

export default {
  name: 'shareDetail',
  components: { ShareDeailGraphV_3, ShareDeailGraphV_2, ShareDetailBar, ShareDetailGraph },
  props: {
    query: {
      type: Object,
      default: () => {
        return {}
      }
    },
    orgList: {
      type: Array,
      default: () => {
        return []
      }
    },
    selectOrgList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  watch: {
    loading: {
      handler(value) {
        if (value) {
          this.startLoading('shareDetail')
        } else {
          if (this.loadingView == null) {
            return
          }
          this.loadingView.close()
        }
      }
    }
    // query: {
    //   handler(value) {
    //     if (Object.keys(value).length == 0) {
    //       return
    //     }
    //     this.getAllData()
    //   },
    //   deep: true,
    //   immediate: true
    // }
  },
  data() {
    return {
      loading: false,
      loadingView: null,
      chartList: [],
      statusInfo: {},
      incomeInfo: {}
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.getAllData()
    })
  },
  methods: {

    getAllData: function() {
      let sharePoolIncomeCount = (query) => {
        return new Promise(resolve => {
          this.$api.deviceOrg.sharePoolIncomeCount(query).then(res => {
            let info = {
              'categoryCount': 0,
              'deviceCount': 0,
              'incomeCount': 0,
              'loanCount': 0,
              'tenantCount': 0
            }
            if (this.$valueIsExist(res, 'data')) {
              info = res.data
              for (const key in res.data) {
                if (this.$valueIsExist(res.data, key) == false) {
                  continue
                }
                info[key] = res.data[key]
              }
            }
            resolve({ result: true, info: info })
          }).catch(e => {
            resolve({
              result: true, info: {
                'categoryCount': 0,
                'deviceCount': 0,
                'incomeCount': 0,
                'loanCount': 0,
                'tenantCount': 0
              }
            })
          })
        })
      }

      let getGraphData = (query) => {
        return new Promise(resolve => {
          this.$api.deviceOrg.countForTenantByStatus(query).then(res => {
            let info = {
              approve: '-',
              back: '-',
              borrow: '-',
              repairs: '-',
              shareDevice: '-',
              shareOrder: '-',
              subscribe: '-'
            }
            if (this.$valueIsExist(res, 'data')) {
              info = res.data
              for (const key in res.data) {
                if (this.$valueIsExist(res.data, key) == false) {
                  continue
                }
                info[key] = res.data[key]
              }
            }
            resolve({ result: true, info: info })
          }).catch(e => {
            resolve({
              result: true, info: {
                approve: '-',
                back: '-',
                borrow: '-',
                repairs: '-',
                shareDevice: '-',
                shareOrder: '-',
                subscribe: '-'
              }
            })
          })
        })
      }

      let getLineData = (query) => {
        return new Promise(resolve => {
          this.$api.deviceOrg.countForTenantBorrow(query).then(res => {
            let list = []
            if (this.$valueIsExist(res, 'data')) {
              list = res['data']
            }
            resolve({ result: true, list: list })
          }).catch(e => {
            resolve({ result: true, list: [] })
          })
        })
      }

      let timeType = 1
      let timeStr = this.formatDayDate(new Date().getTime())
      let startTime = timeStr + ' 00:00:00'
      let endTime = timeStr + ' 23:59:59'
      let userInfo = this.$store.getters.userInfo
      let tenantId = userInfo.tenantId
      let query = {
        isTenantIdChild: 1,
        isCategoryIdChild: 1,
        tenantId: tenantId,
        timeType: timeType,
        startTime: startTime,
        endTime: endTime
      }

      this.loading = true
      Promise.all([getGraphData(query), getLineData(query), sharePoolIncomeCount(query)]).then(res => {
        this.statusInfo = res[0].info
        this.chartList = res[1].list
        this.incomeInfo = res[2].info
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.shareDetail {
  width: 100%;
  height: calc(100% - 10px);

  .shareDetailView {
    height: calc(100% - 10px);
    background-color: white;
  }

}

</style>
